<template>
  <div class="dashboard">
      <form action="">
        <div class="lessons-container" v-if="isNext">
          <div class="category-container">
          <v-text-field label="Course Name" v-model="course.titleName"></v-text-field>
        </div>

          <div class="category-container">
            <v-select label="Price"
            :items="priceTiers"
            item-title="displayName"
            item-value="id"
            v-model="selectedPriceTierId"
            ></v-select>
            <!-- <v-text-field label="Price" :placeholder="0.00" v-model="course.price"></v-text-field> -->
          </div>
          <div class="category-container">
            <v-select label="Course Category"
            :items="categories"
            item-title="name"
            item-value="id"
            v-model="selectedCategory"
            ></v-select>
          </div>

          <div class="thumbnail-container">
           <div class="input-container">
            <label for="">Course Thumbnail</label>
            <input label="" type="file" ref="courseFile"  accept=".png, .jpeg, .jpg" :multiple="false" @change="selectCourseFile">
           </div>
            <img v-if="courseThumbnailFile != null" :src="generateURL(courseThumbnailFile)" class="">
        </div>

        <div class="category-container">
          <v-textarea label="Course Description" placeholder="Enter description for the course" v-model="course.description"></v-textarea>
          </div>
        </div>
       
        <div class="add-sections-container" v-else>
          <div class="course-name-container">
               <label for="">Add New Section</label>
               <img src="../assets/add-icon.svg" @click.prevent="handleAddSection">
            </div>
            <div class="section-cell" v-for="section, index in sections" :key="section.id">
            <div class="course-name-container">
              <label class="section-title-label">Section {{ index + 1 }}</label>
               <img src="../assets/delete.svg" @click.prevent="handleDeleteSection(section)">
            </div>
             <div class="course-name-container">
               <label for="">Lessons</label>
               <img src="../assets/add-lesson.svg" @click.prevent="handleAddLesson(index)">
            </div>
            <div class="option-container" v-if="section.isAddLesson">
                <h3>Options</h3>
              <v-divider></v-divider>
              <div class="option-cell" :class="{'is-selected': selectedOption === option}" v-for="option in section.options" :key="option" @click="didSelect(option)">
                <h3>{{ option }}</h3>
              </div>
             <button class="confirm-option-btn" @click.prevent="handleConfirm(index)">Confirm</button>
          </div>
           <div class="lesson-cell" v-for="lesson in section.lessons" :key="lesson.id">
            <div class="category-container">
              <v-text-field :label="lesson.type === 'video' ? 'Video Lesson Name' : lesson.type === 'slides' ? 'Slide Name' : 'Quiz Name'" v-model="lesson.titleName"></v-text-field>
            </div>
              <input label="" type="file" :ref="'file_' + `${index}`"  accept=".mp4, .mov" :multiple="false" @change="selectVideoFile(index)" v-if="lesson.type === 'video'">
              <input label="" type="file" :ref="'slide_' + `${index}`" accept=".pptx, .ppt" :multiple="true" @change="selectedSlides" v-if="lesson.type === 'slides'">
              <input label="" type="file" :ref="'quiz_' + `${index}`" :multiple="true" @change="selectQuizFile" v-if="lesson.type === 'quiz'">
           </div>
          </div>
        </div>

        <button class="save-btn" @click.prevent="handleSave"> {{ isNext ? 'Continue' : 'Save' }} </button>
      </form>
  </div>
</template>

<script>
import axios from 'axios';
import APIService from '@/APIService';
import _ from 'lodash'

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      course: {
        titleName: "", 
        description: "",
        instructorId: "instructorId",
        category: this.selectedCategory, 
        type: "video", 
        lessons: [],
        price: null, 
        priceTier: "Tier0",
        id: Date.now().toString()
      }, 
      courseThumbnailFile: null,
      lessons: [], 
      selectedOption: null, 
      categories: [], 
      selectedCategory: null, 
      isNext: true,
      sections: [], 
      priceTiers: [],
      selectedPriceTierId: null,
      header: {
        'Access-Control-Allow-Origin': true, 
        'Content-Type': 'application/json'
       }
    }
  }, 
  async mounted() { // Ubishark Online Video Course | Course Tier 3  | Course_Tier3
    this.categories = await APIService.getCategories()
    this.priceTiers = [
      { id: "Tier0", price: 0, displayName: "Free" }, 
      { id: "Tier1", price: 0.99, displayName: "USD0.99" }, 
      { id: "Tier2", price: 1.99, displayName: "USD1.99"  }, 
      { id: "Tier3", price: 2.99, displayName: "USD2.99"  }, 
      { id: "Tier4", price: 3.99, displayName: "USD3.99"  }, 
      { id: "Tier5", price: 4.99, displayName: "USD4.99"  }, 
      { id: "Tier6", price: 5.99, displayName: "USD5.99"  }, 
      { id: "Tier7", price: 6.99, displayName: "USD6.99"  }, 
      { id: "Tier8", price: 7.99, displayName: "USD7.99"  }, 
      { id: "Tier9", price: 8.99, displayName: "USD8.99"  }, 
      { id: "Tier10", price: 9.99, displayName: "USD9.99"  }, 
      { id: "Tier11", price: 12.00, displayName: "USD12.00"  }, 
      { id: "Tier12", price: 15.00, displayName: "USD15.00"  }, 
      { id: "Tier13", price: 20.00, displayName: "USD20.00"  }, 
      { id: "Tier14", price: 30.00, displayName: "USD30.00"  }, 
      { id: "Tier15", price: 40.0, displayName: "USD40.00"  }, 
      { id: "Tier16", price: 50.00, displayName: "USD50.00"  }, 
      { id: "Tier17", price: 60.00, displayName: "USD60.00"  }, 
      { id: "Tier18", price: 70.00, displayName: "USD70.00"  }, 
      { id: "Tier19", price: 80.00, displayName: "USD80.00"  }, 
      { id: "Tier20", price: 90.00, displayName: "USD90.00"  }, 
      { id: "Tier21", price: 100.00, displayName: "USD100.00"  }, 
      { id: "Tier22", price: 150.00, displayName: "USD150.00"  }, 
      { id: "Tier23", price: 200.00, displayName: "USD200.00"  }, 
      { id: "Tier24", price: 300.00, displayName: "USD300.00"  }, 
      { id: "Tier25", price: 500.00, displayName: "USD500.00"  }
    ]
  },
  methods: {
    handleDeleteSection() {

    },
    handleAddSection() {
      this.sections.push({ id: Date.now(), isAddLesson: false,  options: [ "Video", "Slides", "Quiz"], lessons: [] })
    },
    selectVideoFile(index) { // courseId
      let refName = 'file_' + index
      console.log('refs: ', this.$refs, 'index file: ', this.$refs[refName][0].files[0])
      let fileObject = this.$refs[refName][0].files[0]
      // console.log('files: ', this.$refs.file[0].files[0], 'sectionIndex: ', index)
      let lessonIndex = _.findIndex(this.sections[index].lessons, function(o) { return o.type === 'video'; });
      this.sections[index].lessons[lessonIndex].files.push(fileObject)
      console.log('selected file: ', fileObject, 'lessons: ', this.sections)
    },
    selectCourseFile() {
      let fileObject = this.$refs.courseFile.files[0] // .files[0]
      console.log('thumbanil file: ', fileObject)
      this.courseThumbnailFile = fileObject
    },
    selectedSlides() { // courseId
    
    },
    selectQuizFile() { // courseId
  
    },
    generateURL(file) {
       let fileSrc = URL.createObjectURL(file);
       return fileSrc;
    },
    async processSections() {
      await this.sections.reduce(async (promise, section) => {
          await promise;
          await this.processLessons(section.lessons)
      }, Promise.resolve());
    },
    async processLessons(lessons) {
      await lessons.reduce(async (promise, lesson) => {
          await promise;
          let files = lesson.files
          await this.uploadLessonFiles(files, lesson.titleName, lesson.type)
      }, Promise.resolve());
      console.log('files upload complete')
    },
    async handleSave() {
      console.log('priceTier: ', this.selectedPriceTierId)
      if (this.selectedPriceTierId == null) {
        console.log('return because pricetier is null')
        return 
      }
      console.log('Save initiated')
      if (this.isNext)  {
        this.sections.push({ id: Date.now(), isAddLesson: false,  options: [ "Video", "Slides", "Quiz"], lessons: [] })
        this.isNext = false
      } else {
       this.course.category = this.selectedCategory
       console.log('processing sections')
       await this.processSections()
      // course.instructorId TODO: Fix instructor id
      console.log('Saving course')
      await this.saveCourse()
      }
    },
    async saveCourse() {
        const formData = new FormData()
        const priceTier = this.priceTiers.find(item => item.id === this.selectedPriceTierId);
        this.course.priceTier = priceTier.id
        this.course.price = priceTier.price
        formData.append('postJson', JSON.stringify(this.course))
        formData.append('attachment', this.courseThumbnailFile, 'fileName')
        await axios.post("https://api.ubishark.com/v1/course", formData, this.header).then((response) => {
          console.log("axio response:", response);
            if (response.status == 200 && response.data.statusCode == 200) {
              let resp = response.data.resp 
              console.log('resp: ', resp)
              this.course.lessons.push(resp.id)
            } else {
                alert(`Failed with errors: ${response.data}`)
            }
        });
    },
    async uploadLessonFiles(lessonFiles, lessonName, lessonType) {
      let postJson = {
         titleName: lessonName, 
         type: lessonType,
         id: Date.now()
      }
      await lessonFiles.reduce(async (promise, file) => {
          await promise;
          const formData = new FormData()
          formData.append('postJson', JSON.stringify(postJson))
          formData.append('attachment', file, 'fileName')
          await axios.post("https://api.ubishark.com/v1/course/lesson", formData, this.header).then((response) => {
            console.log("axio response:", response);
            if (response.status == 200 && response.data.statusCode == 200) {
              let resp = response.data.resp 
              console.log('resp: ', resp)
              this.course.lessons.push(resp.id)
            } else {
                alert(`Failed with errors: ${response.data}`)
            }
        });
      }, Promise.resolve());
    },
    didSelect(option) {
      this.selectedOption = option
    },
    handleConfirm(index) {
      this.sections[index].isAddLesson = false 
      this.sections[index].lessons.push({
        titleName: "", 
        id: Date.now(), 
        type: this.selectedOption.toLowerCase(), 
        files: []
      })
      console.log('course: ', this.course)
      let option = this.selectedOption
      this.sections[index].options = _.filter(this.sections[0].options, function(o) { return o !== option });  
      this.selectedOption = null
    },
    handleAddLesson(index) {
      this.sections[index].isAddLesson = true 
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
 .option-container {
    position: absolute;
    z-index: 9999;
    height: 240px;
    width: 250px;
    margin-left: 350px;
    margin-top: 100px;
    border-radius: 4px;
    background-color: rgb(228, 228, 228);
    .option-cell {
      height: 30px;
      &.is-selected {
        background-color: rgb(251, 193, 172);
      }
    }
    h3 {
      margin-top: 10px;
    }
    .confirm-option-btn {
      margin-top: 30px;
      color: white;
      background-color: rgb(8, 77, 142);
      height: 34px;
      width: 120px;
      border-radius: 8px;
    }
  }

.section-cell {
  display: flex;
  flex-direction: column;
  min-height: 120px;
  background-color: rgb(244, 244, 244);
  margin-bottom: 20px;
  border-radius: 8px;
  padding-left: 20px;
  .section-title-label {
    width: 100%;
    text-align: left;
    float: left;
    margin: 20px 0 10px 0;
  }
}
.thumbnail-container {
  padding: 10px 10px 10px 15px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  height: 90px;
  width: 90%;
  background-color: rgb(244, 244, 244);
  margin-bottom: 20px;
  border-radius: 8px;

  img {
    border-radius: 8px;
    object-fit: contain;
  }
}
.input-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
}
.category-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  overflow: hidden;
  label {
    float: left;
    text-align: left;
  }
}
.save-btn {
  height: 50px;
  width: 90%;
  color: white;
  background-color: rgb(8, 77, 142);
  margin-top: 50px;
  font-weight: 600;
  border-radius: 8px;
}
form {
  display: flex;
  flex-direction: column;
  width: 600px;
  max-height: 80vh;
  margin-right: auto;
  margin-left: auto;
}

.course-name-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 34px;
    height: 34px;
    margin-left: 8px;
    margin-top: 10px;
    margin-right: 20px;
  }
}
.lessons-container {
  height: 90%;
  width: 100%;
  overflow-y: scroll;
}

.lesson-cell {
  display: flex;
  flex-direction: column;
  height: 140px;
  width: 90%;
  background-color: rgb(244, 244, 244);
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 10px 15px 0 15px;
}

</style>
