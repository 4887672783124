<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
  components: {
    
  }
}
</script>

<style>
:root {
  --color-primary: #237AFF;
  --color-primary-yellow: #EAB830;
  --color-graphic-green: #10C45C;
  --color-dark-primary: #1C2634;
  --color-light-primary: #6c7278;
  --color-primary-pink: #7380ec;
  --color-danger: #ff7782;
  --color-success:#41f1b6;
  --color-primary-green: #1dbb6c;
  --color-warning: #ffbb55;
  --color-info-dark: #7d8da1;
  --color-info-light: #dce1eb;
  --color-dark: #363949;
  --color-light: rgba(132, 139, 200, 0.18);
  --color-light-variant: rgba(255, 255, 255, 0.38);
  --color-primary-variant: #07db71;
  --color-dark-variant: #677483;
  --color-background: #f6f6f9;
  --color-card-background: white;
  --color-card-title: #1C2634;
  --color-light-yellow: #fff8df;

  /* FAFAFA f6f6f9 */
  --color-blue: #4d81E7;
  --color-light-orange: #FDEFD9;
  --color-dark-theme: #1A1C1E;
  --color-light-green: #12B3A8;
  --color-bar-dark: #262626;
  --color-light-pink: #f6f5ff;

  --card-border-radius: 2rem;
  --border-radius-1: 0.4rem;
  --border-radius-2: 0.8rem;
  --border-radius-3: 1.2rem;
  --card-padding: 1.8rem;
  --padding-1: 1.2rem;
  --box-shadow: 0 2rem 3rem var(--color-light);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
