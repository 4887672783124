<template>
  <div class="home">
    <div class="top-view">
        <div class="top-content-view">
            <NavBar/>
            <div class="top-container">
                <img src="../assets/banner.jpg">
                <div class="top-right-container">
                    <div class="instructor-enterprise">
                      <ButtonIconView btnTitle="Become an Instructor" iconName="megaphone.png"/>
                      <ButtonIconView btnTitle="For Enterprise" iconName="suitecase.png"/>
                    </div>
                    <div class="auth-container">
                        <ButtonIconView btnTitle="Login" iconName="user.png"/>
                       <button>Sign Up</button>
                       <img src="../assets/favorite.png" alt="">
                    </div>
                </div>
            </div>
            <h1>OLINE LEARNING IS HERE <br><span>THE WAIT IS OVER</span></h1>
            <button class="get-started-btn">GET STARTED?</button>
            <HomeCategoryView class="category-view"/>
        </div>
    </div>
    <div class="top-filter-container">
        <h2>Top Courses</h2>
        <ButtonIconView class="filter-container" btnTitle="Release date" iconName="logo.jpg" :isRightToLeft="true" titleColor="#000000"/>
    </div>
    <div class="course-grid">
        <div class="course-cell" v-for="course in courses" :key="course">
            <img :src="course.thumbnail">
            <span for="">{{ course.category.name }}</span>
            <label for="">{{ course.titleName }}</label>
            <p>{{ formatPrice(course.price) }}</p>
        </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import ButtonIconView from '@/components/ButtonIconView.vue';
import HomeCategoryView from '@/components/HomeCategoryView.vue';
import APIService from '@/APIService';
export default {
    components: {
        NavBar, ButtonIconView, HomeCategoryView
    }, 
    data() {
        return {
            courses: []
        }
    }, 
    mounted() {
        this.getCourses()
    },
    methods: {
        formatPrice(price) {
            const totalPriceCedis = price * 16
            return price > 0 ? `Ghc${totalPriceCedis}` : `Free`
        },
        async getCourses() {
           this.courses = await APIService.getAllCourses()
        }
    }
}
</script>

<style lang="scss" scoped>
.top-filter-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    width: 1200px;
    margin: auto;
    h2 {
        font-weight: 600;
        font-size: 24px;
    }
    .ilter-container {
        height: 40px;
        width: 180px;
        border: 1px solid var(--color-light);
    }
}
.course-grid {
  display: grid;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  grid-template-columns: repeat(auto-fill, 220px);
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  justify-content: center;
  .course-cell {
    display: flex;
    text-align: left;
    flex-direction: column;
    border: 1px solid var(--color-light);
    p {
        margin-left: 10px;
        font-weight: 600;
        font-size: 13px;
        margin-top: 8px;
    }
    span {
        padding: 10px;
        color: var(--color-info-dark);
        font-size: 12px;
    }
    label {
        padding: 0 10px 0 10px;
        font-size: 15px;
        font-weight: 600;
        max-height: 44px;
        overflow: hidden;
    }
    img {
        object-fit: cover;
        overflow: hidden;
        height: 180px;
        width: 100%;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
    }
  }
}

.course-grid>* {
  height: 300px;
  border-radius: 5px;
}

.home {
    height: 100vh;
    width: 100vw;
    .top-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        img {
            width: 200px;
            height: 100px;
            object-fit: contain;
        }
        .top-right-container {
            display: flex;
            gap: 80px;
            margin-right: 30px;
            .instructor-enterprise, .auth-container  {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20px;
            }
            .auth-container {
                img {
                    height: 24px;
                    width: 24px;
                }
                button {
                    width: 100px;
                    height: 34px;
                    border-radius: 4px;
                    color: white;
                    font-weight: 600;
                    background-color: var(--color-primary);
                }
            }
        }
    }
    .top-content-view {
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        .category-view {
            margin: 80px auto 0 auto;
        }
        h1 {
            color: var(--color-primary-yellow);
            font-size: 70px;
            margin-top: 20px;
            font-weight: 800;
            span {
                color: white;
            }
        }
        .get-started-btn {
            width: 200px;
            height: 50px;
            font-weight: 600;
            font-size: 14px;
            color: white;
            border: 2px solid white;
            padding: 10px;
            border-radius: 25px;
            margin-top: 20px;
        }
    }
    .top-view {
     background-image: url('https://www.incimages.com/uploaded_files/image/1920x1080/getty_933383882_2000133420009280345_410292.jpg');
     background-size: cover;
     background-position: center center;
     overflow: hidden;
     height: 800px;
     width: 100vw;
   }
}

</style>