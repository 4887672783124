<template>
  <div class="nav-bar">
    <div class="nav-cell" v-for="item in navItems" :key="item.id">
        {{ item.title }}
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            navItems: [
             {title: 'Courses', id: 'course'}, 
             {title: 'Plans', id: 'plans'}, 
             {title: 'Register', id: 'register'}, 
             {title: 'Wishlist', id: 'wishlist'}, 
             {title: 'My Account', id: 'account'},
             {title: 'Checkout', id: 'checout'}, 
             {title: 'Cart', id: 'cart'}, 
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.nav-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 480px;
    float: right;
    gap: 15px;
    margin-top: 20px;
    .nav-cell {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        font-weight: 500;
        font-size: 14px;
        color: var(--color-info-light);
    }
}

</style>